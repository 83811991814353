// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-components-aboutus-js": () => import("./../../../src/page-components/aboutus.js" /* webpackChunkName: "component---src-page-components-aboutus-js" */),
  "component---src-page-components-coffee-roaster-js": () => import("./../../../src/page-components/coffee-roaster.js" /* webpackChunkName: "component---src-page-components-coffee-roaster-js" */),
  "component---src-page-components-community-js": () => import("./../../../src/page-components/community.js" /* webpackChunkName: "component---src-page-components-community-js" */),
  "component---src-page-components-community-news-js": () => import("./../../../src/page-components/community-news.js" /* webpackChunkName: "component---src-page-components-community-news-js" */),
  "component---src-page-components-consumer-js": () => import("./../../../src/page-components/consumer.js" /* webpackChunkName: "component---src-page-components-consumer-js" */),
  "component---src-page-components-contact-js": () => import("./../../../src/page-components/contact.js" /* webpackChunkName: "component---src-page-components-contact-js" */),
  "component---src-page-components-cookie-policy-js": () => import("./../../../src/page-components/cookie-policy.js" /* webpackChunkName: "component---src-page-components-cookie-policy-js" */),
  "component---src-page-components-estates-js": () => import("./../../../src/page-components/estates.js" /* webpackChunkName: "component---src-page-components-estates-js" */),
  "component---src-page-components-facilitator-js": () => import("./../../../src/page-components/facilitator.js" /* webpackChunkName: "component---src-page-components-facilitator-js" */),
  "component---src-page-components-hospitality-js": () => import("./../../../src/page-components/hospitality.js" /* webpackChunkName: "component---src-page-components-hospitality-js" */),
  "component---src-page-components-index-js": () => import("./../../../src/page-components/index.js" /* webpackChunkName: "component---src-page-components-index-js" */),
  "component---src-page-components-micro-roaster-js": () => import("./../../../src/page-components/micro-roaster.js" /* webpackChunkName: "component---src-page-components-micro-roaster-js" */),
  "component---src-page-components-press-media-js": () => import("./../../../src/page-components/press-media.js" /* webpackChunkName: "component---src-page-components-press-media-js" */),
  "component---src-page-components-price-crisis-js": () => import("./../../../src/page-components/price-crisis.js" /* webpackChunkName: "component---src-page-components-price-crisis-js" */),
  "component---src-page-components-privacy-policy-js": () => import("./../../../src/page-components/privacy-policy.js" /* webpackChunkName: "component---src-page-components-privacy-policy-js" */),
  "component---src-page-components-solutions-js": () => import("./../../../src/page-components/solutions.js" /* webpackChunkName: "component---src-page-components-solutions-js" */),
  "component---src-page-components-story-js": () => import("./../../../src/page-components/story.js" /* webpackChunkName: "component---src-page-components-story-js" */),
  "component---src-page-components-sustainability-js": () => import("./../../../src/page-components/sustainability.js" /* webpackChunkName: "component---src-page-components-sustainability-js" */),
  "component---src-page-components-terms-and-condition-js": () => import("./../../../src/page-components/terms-and-condition.js" /* webpackChunkName: "component---src-page-components-terms-and-condition-js" */),
  "component---src-page-components-traceability-js": () => import("./../../../src/page-components/traceability.js" /* webpackChunkName: "component---src-page-components-traceability-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-aboutus-js": () => import("./../../../src/pages/en/aboutus.js" /* webpackChunkName: "component---src-pages-en-aboutus-js" */),
  "component---src-pages-en-coffee-roaster-js": () => import("./../../../src/pages/en/coffee-roaster.js" /* webpackChunkName: "component---src-pages-en-coffee-roaster-js" */),
  "component---src-pages-en-community-js": () => import("./../../../src/pages/en/community.js" /* webpackChunkName: "component---src-pages-en-community-js" */),
  "component---src-pages-en-community-news-js": () => import("./../../../src/pages/en/community-news.js" /* webpackChunkName: "component---src-pages-en-community-news-js" */),
  "component---src-pages-en-consumer-js": () => import("./../../../src/pages/en/consumer.js" /* webpackChunkName: "component---src-pages-en-consumer-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-cookie-policy-js": () => import("./../../../src/pages/en/cookie-policy.js" /* webpackChunkName: "component---src-pages-en-cookie-policy-js" */),
  "component---src-pages-en-estates-js": () => import("./../../../src/pages/en/estates.js" /* webpackChunkName: "component---src-pages-en-estates-js" */),
  "component---src-pages-en-facilitator-js": () => import("./../../../src/pages/en/facilitator.js" /* webpackChunkName: "component---src-pages-en-facilitator-js" */),
  "component---src-pages-en-hospitality-js": () => import("./../../../src/pages/en/hospitality.js" /* webpackChunkName: "component---src-pages-en-hospitality-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-micro-roaster-js": () => import("./../../../src/pages/en/micro-roaster.js" /* webpackChunkName: "component---src-pages-en-micro-roaster-js" */),
  "component---src-pages-en-press-media-js": () => import("./../../../src/pages/en/press-media.js" /* webpackChunkName: "component---src-pages-en-press-media-js" */),
  "component---src-pages-en-price-crisis-js": () => import("./../../../src/pages/en/price-crisis.js" /* webpackChunkName: "component---src-pages-en-price-crisis-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-solutions-js": () => import("./../../../src/pages/en/solutions.js" /* webpackChunkName: "component---src-pages-en-solutions-js" */),
  "component---src-pages-en-sustainability-js": () => import("./../../../src/pages/en/sustainability.js" /* webpackChunkName: "component---src-pages-en-sustainability-js" */),
  "component---src-pages-en-terms-and-condition-js": () => import("./../../../src/pages/en/terms-and-condition.js" /* webpackChunkName: "component---src-pages-en-terms-and-condition-js" */),
  "component---src-pages-en-traceability-js": () => import("./../../../src/pages/en/traceability.js" /* webpackChunkName: "component---src-pages-en-traceability-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

